import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';
import { AttractionLogo } from 'public/components';
import chevronRight from 'common/assets/chevron-right.svg';
import './hours-container.less';

const TIME_ZONE = 'America/New_York';

const HoursContainer = ({
  venue,
  image,
  name,
  openingHours,
  date,
  linkTo,
  hasNoHours,
  sundayClosedMessage,
  sundayClosedLink,
}) => {
  const className = useClassName('HoursContainer');

  const getTimeFromDate = (date) => date.toFormat('h:mm a');

  const handleLinkClicked = () => track(`Clicked on ${name}`);

  const getDateTime = (operationHours, hours, minutes) =>
    operationHours &&
    DateTime.fromISO(date)
      .setZone(TIME_ZONE)
      .set({ hour: hours })
      .set({ minute: minutes });

  const shouldRenderHours = () =>
    openingHours &&
    (openingHours.regular.length > 0 || openingHours.irregular.length > 0);

  const getOpenHours = (
    closedAllDay,
    temporarilyClosed,
    startTime,
    endTime
  ) => {
    if (temporarilyClosed) return 'Closed';

    if (closedAllDay) {
      const weekday = DateTime.fromISO(date).weekday;
      if (weekday !== 7 || !sundayClosedMessage || !sundayClosedLink)
        return 'Closed';
      return (
        <Link className={className('closed-message')} to={sundayClosedLink}>
          {sundayClosedMessage}
        </Link>
      );
    }

    return `${getTimeFromDate(startTime)} - ${getTimeFromDate(endTime)}`;
  };

  const renderHours = () => {
    const temporarilyClosed = openingHours.temporarilyClosed;
    const irregular = openingHours.irregular.find(
      (irregular) => irregular.date === date
    );
    const regular = Object.values(openingHours.regular).find(
      (hours) =>
        hours.weekday === DateTime.fromISO(date).toFormat('cccc').toLowerCase()
    );

    const closedAllDay =
      (irregular && irregular.isClosed) ||
      (!irregular && regular.isClosed) ||
      openingHours.temporarilyClosed;

    const operationHours = closedAllDay ? null : irregular || regular;

    const startTime = getDateTime(
      operationHours,
      operationHours?.openHour,
      operationHours?.openMinute
    );
    const endTime = getDateTime(
      operationHours,
      operationHours?.closeHour,
      operationHours?.closeMinute
    );

    return (
      <div className={className('operation-hours-container')}>
        <p>
          {getOpenHours(closedAllDay, temporarilyClosed, startTime, endTime)}
        </p>
      </div>
    );
  };

  const renderViewhours = () => (
    <div className={className('operation-hours-container')}>
      <p>View Hours</p>
    </div>
  );

  if (!openingHours && !hasNoHours) return null;

  return (
    (shouldRenderHours() || hasNoHours) && (
      <Link
        className={className('container')}
        to={linkTo}
        onClick={handleLinkClicked}>
        <div className={className('logo')}>
          {venue ? (
            <AttractionLogo venue={venue} />
          ) : (
            <img src={image} alt={name} />
          )}
        </div>
        <div className={className('content')}>
          {shouldRenderHours() && renderHours()}
          {hasNoHours && renderViewhours()}
          <p>{name}</p>
        </div>
        <img src={chevronRight} alt={linkTo} />
      </Link>
    )
  );
};

HoursContainer.propTypes = {
  venue: PropTypes.object,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  openingHours: PropTypes.object,
  date: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  hasNoHours: PropTypes.bool,
  sundayClosedMessage: PropTypes.string,
  sundayClosedLink: PropTypes.string,
};

HoursContainer.defaultProps = {
  hasNoHours: false,
};

export default HoursContainer;
